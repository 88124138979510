.title {
  font-family: "Carter One";
}
.container {
  margin-top: 50px;
  text-align: center;
}

.images {
  display: flex;
  max-width: 80%;
  margin: 2% auto;
  flex-wrap: wrap;
  align-items: flex-start;
}
.single-photo {
  max-width: 300px;
  align-self: auto;
  margin: 1% 2px;
  flex-grow: 1;
  vertical-align: middle;
}
